import React from "react";

export default class InputDelButton extends React.Component {
	constructor(props) {
		super(props);

		const value = this.props.value ? this.props.value : '';
		this.state = { isFocus: false, value: value }

		this.handleInput = this.handleInput.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	handleInput(e) {
		let val = e.target.value;
		if(this.props.maxLength && this.props.maxLength < val.length) {
			val = val.substr(0, this.props.maxLength);
		}
		this.setState({value: val});
		this.props.onInput(this.props.name, val);
	}

	handleFocus(e) {
		this.setState({ isFocus: true });
	}

	handleBlur(e) {
		this.setState({ isFocus: false });
	}

	handleReset(e) {
		this.setState({ value: '' });
		this.props.onInput(this.props.name, '');
	}

	render() {
		const delButton = this.state.value && (
			<span className="btnTxtDel" style={{ display: 'inline' }} onClick={this.handleReset}></span>
		);
		const classes = this.state.isFocus ? "input input-del focus" : "input input-del";
		return (
			<>
				<input type={this.props.type} name={this.props.name} value={this.state.value} placeholder={this.props.placeholder} className={classes} maxLength={this.props.maxLength} ref={this.input} onChange={this.handleInput} onFocus={this.handleFocus} onBlur={this.handleBlur} />
				{delButton}
			</>
		)
	}
}