import React, { useEffect } from "react"
import { withRouter } from 'react-router-dom'
import Footer from './Footer'
import CategoryMenu from './CategoryMenu'
import ShopList from "./ShopList"
import Filter from './Filter'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import { gqlErrorHandler } from './Library'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import dayjs from 'dayjs'
import _ from 'lodash'

const searchWordGql = loader('../gql/search_word.gql')
class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			keyword: this.props.match.params.keyword || '',
			keyword_tmp: this.props.match.params.keyword || '', /* 요구에 따라 typing으로 바꿀수도있음, 일단이걸로. */
			history: (JSON.parse(localStorage.getItem('s_history')) || []),
		}
		this.textInput = React.createRef();
		this.unmounted = false
		this.debounceInput = _.debounce(() => {
			if (this.unmounted === true) return /* '...memory leak...'에러 방지용, debounce가 Search가 언마운트되었을 때 set state하면 발생함.*/
			this.setState(({ keyword_tmp }) => (
				{ keyword: keyword_tmp }
			))
		}, 800)
	}
	componentDidUpdate() {
		window.scrollTo(0, 0)
	}
	componentWillUnmount() {
		this.unmounted = true
	}
	handleKeywordChange = (e) => {
		const value = e.target.value.replace(/%/, '')
		this.setState({ keyword_tmp: value })
		this.debounceInput()
	}
	handleSearch = (e, keyword = null) => {
		keyword = keyword || this.state.keyword_tmp

		e.preventDefault();
		if ('' === keyword) return;

		// state & localStorage의 history갱신
		let history = [keyword];
		for (const h of this.state.history) {
			if (h !== keyword) history.push(h);
		}
		history = history.slice(0, 9); //10개만;
		this.setState({ keyword, keyword_tmp: keyword, history }, this.saveHistory);
		this.textInput.current.blur() /* focus아웃 해줘야만 다시 클릭할 때 search페이지로 옴. */

		// 결과페이지로 GO
		this.props.history.push(`/search/${keyword}`);
	}
	handleDeleteHistory = (e, key) => {
		e.preventDefault();
		if ('all' === key) {
			this.setState({ history: [] }, this.saveHistory);
		} else {
			let history = [...this.state.history];
			history.splice(key, 1);
			this.setState({ history }, this.saveHistory);
		}
	}
	saveHistory() {
		localStorage.setItem('s_history', JSON.stringify(this.state.history));
	}
	render() {
		const isResult = this.props.match.params.keyword ? true : false
		return <div id="reWrap">
			<Filter>
				{(filter, filterUI, filterBG) => {
					return <>
						<div id="header" className="subHeader">
							<div className="header">
								<a href="#this" className="txtHide btnHback" onClick={(e) => { e.preventDefault(); this.props.history.push('/') }}>뒤로가기</a>
								<div className="hSchBox">
									<form action="" onSubmit={this.handleSearch}>
										<input type="search" ref={this.textInput} value={this.state.keyword_tmp} onChange={this.handleKeywordChange} onFocus={() => isResult && this.props.history.replace('/search')} />
										<a href="#this" className="txtHide btnSch" onClick={this.handleSearch}>검색</a>
									</form>
								</div>
							</div>
							{isResult
								? <>
									<div className="hdSoloMenu"> {/* 검색결과이면 카테고리 보여주기 */}
										<CategoryMenu sUrl={`/search/${this.state.keyword}`} />
									</div>
									{filterUI}
								</>
								: ('' === this.state.keyword
									? <div className="layerHdSch"> {/* 키워드없으면 초기화면 렌더링 */}
										<SearchHistory history={this.state.history} handleDeleteHistory={this.handleDeleteHistory} handleSearch={this.handleSearch} />
										<PopularKeyword handleSearch={this.handleSearch} />
									</div>
									: null
								)
							}
						</div>
						{isResult
							? <>
								{filterBG}
								<div id="container"> {/* 검색한결과 */}
									<div id="contents" className="subCnts4 active">
										<ShopList keyword={this.state.keyword} filter={filter} searchComplete />
									</div>
								</div>
							</>
							: ('' !== this.state.keyword
								?
								<div id="container"> {/* 키워있으면 실시간검색결과 렌더링 */}
									<div id="contents" className="subCnts4">
										<ShopList keyword={this.state.keyword} filter={filter} />
									</div>
								</div>
								: null
							)
						}
					</>
				}}
			</Filter >
			< Footer />
		</div >
	}
}
function PopularKeyword({ handleSearch }) {
	const values = { gid: process.env.REACT_APP_SERVICE_GID, }
	const { loading, error, data } = useQuery(searchWordGql, { variables: values, fetchPolicy: 'cache-and-network' });

	if (loading) return null
	if (error) { gqlErrorHandler(error); return null; }

	const hour = Math.floor(dayjs().hour() / 2) * 2

	let rightIdx = Math.ceil(data.gongSearchWord.length / 2)
	let leftIdx = 0
	let lis = []
	for (let i = 0; i < data.gongSearchWord.length; i++) { /* 순서대로 출력하면 안됨... UI가 왼쪽오른쪽 나위어서 세로로 되어 데이터를 그렇게 만들어줘야함. */
		const idx = i % 2 === 0 ? leftIdx : rightIdx
		const item = data.gongSearchWord[idx]
		lis = [...lis, <li key={idx}><a href="#this" onClick={(e) => handleSearch(e, item.word)}><span className={idx < 3 ? 'grade' : ''}>{idx + 1}</span>{item.word}</a></li>]
		i % 2 === 0 ? leftIdx++ : rightIdx++
	}
	return data.gongSearchWord.length > 0 &&
		<div className="group">
			<h3>인기검색어<span className="date">{dayjs().hour(hour).format('(기준 MM.DD HH:00)')}</span></h3>
			<div className="popularWord">
				<ul>
					{lis}
				</ul>
			</div>
		</div>
}
function SearchHistory({ history, handleDeleteHistory, handleSearch }) {
	useEffect(() => {
		//최근 검색어 스와이프
		new Swiper('.recentWord .swiper-container', {
			slidesPerView: 'auto',
		});
	}, [])

	return history.length > 0
		? <div className="section">
			<div className="group">
				<h3>최근 검색어</h3>
				<a href="#this" className="btnAllDel" onClick={(e) => handleDeleteHistory(e, 'all')}>전체삭제</a>
				<div className="recentWord">
					<div className="swiper-container">
						<ul className="swiper-wrapper">
							{history.map((t, k) => (
								<li key={k} className="swiper-slide"><a href="#this" onClick={(e) => handleSearch(e, t)}>{t}</a><button onClick={(e) => handleDeleteHistory(e, k)} className="txtHide btnDel">삭제</button></li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<hr className="hrBk" />
		</div>
		: null
}
export default withRouter(Search)
