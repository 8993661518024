import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Switch } from 'antd';
import { loader } from 'graphql.macro';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { withApolloClient } from './ApolloClient';
import { gqlErrorHandler } from './Library';
import { Mutation, Query } from '@apollo/client/react/components';
import "../css/antd_switch.css";
import $ from 'jquery';

const SET_PUSH_MUTATION_GQL = loader('../gql/set_push_setting.gql');
const SET_SHOP_PUSH_MUTATION_GQL = loader('../gql/set_push_shop_setting.gql');
const PUSH_SETTING_GQL = loader('../gql/push_setting.gql');
const PUSH_SHOP_SETTING_GQL = loader('../gql/push_shop_setting.gql');
const MEMBER_GQL = loader('../gql/member.gql');

class SetMessage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gong: process.env.REACT_APP_SERVICE_GID,
			useNoticeAlert: true,
			useOrderAlert: true,
			useQnaAlert: true,
			useStampangAdAlert: true,
			shopList: [],
			showTooltip: false
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		//기본 PUSH
		this.props.client.query({
			query: PUSH_SETTING_GQL,
			variables: { gongId: process.env.REACT_APP_SERVICE_GID },
			fetchPolicy: 'network-only',
		}).then((data) => {
			this.setState({
				useNoticeAlert: data.data.gongMember.useNoticeAlert,
				useOrderAlert: data.data.gongMember.useOrderAlert,
				useQnaAlert: data.data.gongMember.useQnaAlert,
			});
		}).catch((err) => { gqlErrorHandler(err) });

		//스탬프팡 PUSH
		this.props.client.query({
			query: MEMBER_GQL,
			fetchPolicy: 'network-only',
		}).then((data) => {
			this.setState({
				useStampangAdAlert: data.data.member.isAgree
			});
		}).catch((err) => { gqlErrorHandler(err) });
	}

	handleChangeShopAgree = (vo) => {
		this.props.client.mutate({
			mutation: SET_SHOP_PUSH_MUTATION_GQL,
			variables: { shopId: vo.shopId, agree: vo.checked },
		}).then((data) => {
			if (!data.data.memberAgree.result) {
				gqlErrorHandler(data.data.memberAgree.msg);
			}
		}).catch((err) => { gqlErrorHandler(err) });
	}
	handleShowTooltip = (e) => {
		e.preventDefault();
		if(!this.state.showTooltip) {
			$('#layerTooltips').slideDown();
		} else {
			$('#layerTooltips').slideUp();
		}		
		this.setState(prevState => ({showTooltip: !prevState.showTooltip}));
	}

	handleFetchMore = (fm, data) => {
		if (data.gongMemberShops.pageInfo.hasNextPage === false) return

		fm({
			variables: {
				gid: process.env.REACT_APP_SERVICE_GID,
				cursor: data.gongMemberShops.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongMemberShops.edges;
				const pageInfo = fetchMoreResult.gongMemberShops.pageInfo;
				return newEdges.length
					? {
						gongMemberShops: {
							__typename: previousResult.gongMemberShops.__typename,
							edges: [...previousResult.gongMemberShops.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		})
	}

	render() {	
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>알림설정</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="setupAlarm">
							<div className="group">
								<h3>기본 푸시 알림</h3>
								<Mutation
									mutation={SET_PUSH_MUTATION_GQL}
									variables={this.state}
									onCompleted={(data) => {
										if (!data.gongMember.result) {
											this.setState({
												useOrderAlert: data.gongMember.useOrderAlert,
												useNoticeAlert: data.gongMember.useNoticeAlert,
												useQnaAlert: data.gongMember.useQnaAlert,
											});
											gqlErrorHandler(data.gongMember.msg);
										}
									}}
								>
									{
										(gongMember, { loading, error }) => {
											const onMutation = (v) => {
												this.setState({
													[v.target.name]: v.target.checked
												}, () => {
													gongMember();
												});
											}
											return (
												<ul>
													<li>
														<span>주문 상태 알림</span>
														<div className="setupSwitch">
															<Switch onChange={(checked) => onMutation({ target: { name: 'useOrderAlert', checked: checked } })} checked={this.state.useOrderAlert} />
														</div>
													</li>
													<li>
														<span>공지 알림</span>
														<div className="setupSwitch">
															<Switch onChange={(checked) => onMutation({ target: { name: 'useNoticeAlert', checked: checked } })} checked={this.state.useNoticeAlert} />
														</div>
													</li>
													<li>
														<span>1:1 문의 답변 알림</span>
														<div className="setupSwitch">
															<Switch onChange={(checked) => onMutation({ target: { name: 'useQnaAlert', checked: checked } })} checked={this.state.useQnaAlert} />
														</div>
													</li>
												</ul>
											)
										}
									}
								</Mutation>
							</div>
							<hr className="hrBk" />
							<div className="group">
								<h3>
									상점별 광고성(마케팅) 알림
									<a href="#this" onClick={(e) => {this.handleShowTooltip(e);}} className="btnHelp"><img src={'../img/reImg/sub/btn_help.png'} alt="도움말" /></a>
								</h3>
								<div className="layerTooltips" id="layerTooltips">
									<div className="toolTip">
										<h3>상점별 광고성(마케팅) 알림</h3>
										<p>
											상점별광고성(이벤트) 푸시및<br />
										SMS 수신여부에대한 설정입니다.<br />
										OFF로 설정하셔도 정보성(주문/공<br />
										지 등)의 알림은 수신 가능합니다.
									</p>
									</div>
									<a href="#this" onClick={(e) => {this.handleShowTooltip(e);}} className="txtHide btnTipClose">닫기</a>
								</div>

								<p className="tipTxt">스탬프팡과 해당 앱 중 하나만 변경해도 한꺼번에 변경됩니다.</p>
								<Query query={PUSH_SHOP_SETTING_GQL} variables={{ gid: process.env.REACT_APP_SERVICE_GID }} fetchPolicy='network-only'  >
									{({ loading, error, data, fetchMore }) => {
										if (loading) return null
										if (error) { gqlErrorHandler(error); return null }
										const entries = data.gongMemberShops.edges || [];

										return (
											<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data)}>
												<ul>
													{
														entries.map((v, k) => (
															<li key={k}>
																<span style={{marginRight: 80}}>{v.node.shop.name}</span>
																<div className="setupSwitch">
																	<Mutation
																		mutation={SET_SHOP_PUSH_MUTATION_GQL}
																		onError={(error) => gqlErrorHandler(error)}
																	>
																		{
																			(memberAgree) => {
																				const onMutation = (v) => {
																					memberAgree({ variables: { shopId: v.target.shopId, agree: v.target.agree } });
																				}
																				return (
																					<Switch
																						onClick={(checked) => onMutation({ target: { shopId: v.node.shop.pk, agree: checked } })}
																						defaultChecked={v.node.marketingAgree}
																					/>
																				)
																			}
																		}
																	</Mutation>
																</div>
															</li>
														))
													}
												</ul>
											</BottomScrollListener>
										)
									}}
								</Query>
							</div>
							<hr className="hrBk" />
							<div className="group">
								<h3>스탬프팡 광고성(마케팅) 알림</h3>
								<p className="tipTxt">스탬프팡 광고성(이벤트) 푸시 및 SMS 수신여부에 대한 설정입니다.</p>
								<ul>
									<li>
										<span>스탬프팡 광고성 알림</span>
										<div className="setupSwitch">
											<Mutation
												mutation={SET_SHOP_PUSH_MUTATION_GQL}
												variables={{ shopId: 0, agree: this.state.useStampangAdAlert }}
												onCompleted={(data) => {
													if (!data.memberAgree.result) {
														this.setState({
															useStampangAdAlert: !this.state.useStampangAdAlert
														});
														gqlErrorHandler(data.memberAgree.msg);
													}
												}}
											>
												{
													(memberAgree, { loading, error }) => {
														const onMutation = (checked) => {
															this.setState({
																useStampangAdAlert: checked
															}, () => {
																memberAgree({ shopId: 0, agree: checked });
															});
														}
														return (
															<Switch onChange={(checked) => onMutation(checked)} checked={this.state.useStampangAdAlert} />
														)
													}
												}
											</Mutation>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withApolloClient(withRouter(SetMessage))