import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { gqlErrorHandler, warningHandler, isLogin } from './Library';
import Modal from './CustomModal';
import { Query, Mutation } from '@apollo/client/react/components';
import BottomScrollListener from 'react-bottom-scroll-listener';
import dateFormat from 'dateformat';

const QNAS_QUERY = loader('../gql/qna_list.gql');
const QNA_QUERY = loader('../gql/qna.gql');
const QNA_MUTATION = loader('../gql/qna_mutation.gql');

const Inquiry = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);
	let match = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${match.path}`} component={InquiryList} />
			<Route exact path={`${match.path}/write`} component={InquiryWrite} />
			<Route path={`${match.path}/view/:id`} component={InquiryView} />
		</Switch>
	)
}

class InquiryList extends React.Component {
	constructor(props) {
		super(props);
		this.handleFetchMore = this.handleFetchMore.bind(this);
		this.doFetchMore = React.createRef(true);
	}

	handleFetchMore = (fm, data) => {
		if (data.gongQnas.pageInfo.hasNextPage === false) return
		if (this.doFetchMore === false) return
		this.doFetchMore.current = false;

		fm({
			variables: {
				gongId: process.env.REACT_APP_SERVICE_GID,
				cursor: data.gongQnas.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongQnas.edges;
				const pageInfo = fetchMoreResult.gongQnas.pageInfo;
				return newEdges.length
					? {
						gongQnas: {
							__typename: previousResult.gongQnas.__typename,
							edges: [...previousResult.gongQnas.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => this.doFetchMore.current = true);
	}

	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>문의하기</h1>
						<a href="tel:1522-4040" class="txtHide btnCsCall">고객센터 전화걸기</a>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts9">
						<div className="comSch2 fixed">
							<div className="tabUi">
								<ul>
									<li><NavLink to="/mypage/faq"><button data-conts="faq" data-btn="sch">FAQ</button></NavLink></li>
									<li><NavLink to="/mypage/inquiry"><button className="on" data-conts="oneTo" data-btn="btns">1:1 문의</button></NavLink></li>
								</ul>
							</div>
							<div className="btns" style={{ display: 'block' }}>
								<NavLink to={isLogin() ? "/mypage/inquiry/write" : "/login"} className="btnTy2 on">1:1 문의하기</NavLink>
							</div>
						</div>
						<div className="inquiryList">
							<div className="oneToOne tabSectoin on" id="oneTo">
								{isLogin() &&
									<Query query={QNAS_QUERY} variables={{ gongId: process.env.REACT_APP_SERVICE_GID }}>
										{({ loading, error, data, fetchMore }) => {
											if (loading) return null
											if (error) { gqlErrorHandler(error); return null }
											const entries = data.gongQnas.edges || [];
											if (entries.length === 0) {
												return <ul>
													<li className="noData">
														<hr className="hrBk" />
														<p>등록된 문의내역이 없습니다.</p>
													</li>
												</ul>
											}
											return (
												<div className="noticeAlarm">
													<div className="topNoti">
														<p>최근 6개월간 문의내역만 조회 가능합니다. </p>
													</div>
													<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data)}>
														<ul>
															{
																entries.map((v, k) => (
																	<li key={k}>
																		<NavLink to={"/mypage/inquiry/view/" + v.node.pk}>
																			<h4>[{v.node.categoryName}]</h4>
																			<p>
																				<span dangerouslySetInnerHTML={{ __html: (v.node.question) }} />
																			</p>
																			<span>{dateFormat(v.node.createdAt, "yyyy-mm-dd")}{v.node.answerAt ? <em className="confirm">답변완료</em> : <em className="wait">미답변</em>}</span>
																		</NavLink>
																	</li>
																))
															}
														</ul>
													</BottomScrollListener>
												</div>
											)
										}}
									</Query>
								}
								{!isLogin() && <ul>
									<li className="noData">
										<hr className="hrBk" />
										<p>로그인 후 이용하실 수 있습니다.</p>
									</li>
								</ul>
								}
							</div>
						</div>
					</div>
				</div>
			</div >
		)
	}
}


class InquiryWrite extends React.Component {
	constructor(props) {
		super(props);
		this.category = [{ code: 'GOODS', name: '상품문의' }, { code: 'SHIPPING', name: '배달문의' }, { code: 'ETC', name: '기타' }]
		this.state = {
			category: 'GOODS',
			content: '',
			file: null,
			filePreview: '',
			gong: process.env.REACT_APP_SERVICE_GID
		}
	}
	categoryChange = (e) => {
		this.setState(
			{ category: e.target.value },
			() => console.log(this.state)
		);
	}
	contentChange = (e) => {
		this.setState({ content: e.target.value });
	}
	fileChange = (attachfile) => {
		//let attachfile = target.files[0];
		this.setState(
			{
				file: attachfile,
				filePreview: URL.createObjectURL(attachfile)
			},
			() => console.log(this.state)
		);
	}
	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage/inquiry" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>문의하기</h1>
						<a href="tel:1522-4040" class="txtHide btnCsCall">고객센터 전화걸기</a>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="faqWrite">
							<div className="sortSelect">
								<div className="selectBox02">
									<select value={this.state.category} onChange={(e) => this.categoryChange(e)} >
										{
											this.category.map((v, k) => (
												<option key={k} value={v.code}>{v.name}</option>
											))
										}
									</select>
								</div>
							</div>
							<div className="txtWrite1"><textarea id="comment" name="comment" onChange={(e) => this.contentChange(e)} placeholder="내용을 입력해주세요."></textarea></div>
							<div className="fileBox">
								<label htmlFor="ex_file" className="txtHide" style={this.state.filePreview ? { backgroundImage: `url(${this.state.filePreview})`, backgroundSize: 'cover' } : {}}>업로드</label>
								<input type="file" id="ex_file" accept="image/*" value="" onChange={({ target: { validity, files: [file] } }) => { validity.valid && this.fileChange(file); }} />
							</div>
							<div className="btnCenter">
								<Mutation
									mutation={QNA_MUTATION}
									variables={this.state}
									onCompleted={(data) => {
										if (data.gongQnaCreate.result) {
											this.setState({
												category: this.category[0].code,
												content: '',
												file: null,
												filePreview: '',
												gong: process.env.REACT_APP_SERVICE_GID
											});
											Modal.success({
												content: "등록이 완료되었습니다.",
												onOk() {
													window.location.href = '/mypage/inquiry';
												}
											});
										} else {
											gqlErrorHandler(data.gongQnaCreate.msg);
										}
									}}
								>
									{
										(gongQnaCreate, { loading, error }) => {
											if (loading) return null
											if (error) { gqlErrorHandler(error); return null }

											if (this.state.content.trim() === '') {
												return <a href="#this" className="btnTy2" onClick={(e) => { e.preventDefault(); warningHandler('입력된 내용이 없습니다.', '내용을 입력해주세요'); }}>등록하기</a>;
											} else {
												return (
													<a href="#this" className="btnTy2 on" onClick={(e) => {
														e.preventDefault();
														Modal.confirm({
															centered: true,
															title: "1:1 문의를 등록하시겠습니까?",
															okText: "확인",
															okType: "warning",
															cancelText: "취소",
															content: "등록한 문의는 수정이나 취소하실 수 없습니다.",
															onOk: gongQnaCreate,
															onCancel: () => { },
														});

													}}>등록하기</a>
												);
											}
										}
									}
								</Mutation>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class InquiryView extends React.Component {
	render() {
		const { id } = this.props.match.params;
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage/inquiry" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>문의하기</h1>
						<a href="tel:1522-4040" class="txtHide btnCsCall">고객센터 전화걸기</a>
					</div>
				</div>
				<div id="container">
					<Query query={QNA_QUERY} variables={{ pk: id }}>
						{({ loading, error, data }) => {
							if (loading) return null
							if (error) { gqlErrorHandler(error); return null }
							if (data.gongQna === '') {
								return <></>
							}
							return (
								<div id="contents" className="subCnts10">
									<div className="faqView">
										<div className="thDiv">
											<h4>[{data.gongQna.categoryName}]</h4>
											<p>{data.gongQna.title}</p>
											<span>{dateFormat(data.gongQna.createdAt, "yyyy-mm-dd")}
												{data.gongQna.answer === '' ? <em className="wait">미답변</em> : <em className="confirm">답변완료</em>}
											</span>
										</div>
										<div className="tdDiv">
											<span style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.gongQna.question }}></span>
											{data.gongQna.questionImgUrl && <div className="img"><img src={data.gongQna.questionImgUrl} alt="" /></div>}
										</div>
										{
											data.gongQna.answer && <div className="answerAdmin">
												{data.gongQna.answerAt && <p style={{ marginBottom: 7, fontSize: 14, color: '#999' }}>{data.gongQna.answerAt}</p>}
												<span style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.gongQna.answer }}></span>
												{
													data.gongQna.answerImgUrl &&
													<div className="img" >
														<img src={data.gongQna.answerImgUrl} alt="" />
													</div>
												}
											</div>
										}
									</div>
								</div>
							)
						}}
					</Query>

				</div>
			</div>
		)
	}
}

export default Inquiry;