import React from "react";
import {withRouter} from "react-router-dom"
import Popup from './Popup';

class PermissionAddr extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chk_addr: false,
		}
		this.handleAgrre = this.handleAgrre.bind(this);
	}
	handleAgrre(e) {
		e.preventDefault();
		if (this.state.chk_addr) {
			localStorage.setItem('permAddr', 'Y');
			this.props.history.replace('/mypage/set-address');
		}
		return false;
	}
	render() {
		return (
			<div id="reWrap">
				<div id="container" style={{ marginTop: 0 }}>
					<div id="contents" className="subCnts">
						<div className="termsAgree">
							<p>원활한 서비스 이용을 위해<br /><strong>이용약관에 동의</strong>해주세요.</p>
							<span>다양한 서비스와 혜택을 위해<br />약관동의가 필요해요!</span>
						</div>
						<div className="agreeJoin">
							<ul>
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_1" checked={this.state.chk_addr} onChange={() => this.setState(prevState => ({ chk_addr: !prevState.chk_addr }))} style={{ display: 'none !important' }} />
										<span className="icon"></span>
										<span className="arrSpan">
											<span className="text">위치 기반 서비스 약관동의 (필수)</span>
											<Popup href="https://smallbiz.notion.site/go_-379194ad854f494e90df7cc094d9703a">
												<img src="/img/reImg/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="통합회원 이용약관" />
											</Popup>
										</span>
									</label>
								</li>
							</ul>
							<div className="btnCenter" style={{marginTop:20}}>
								<a href="#this" onClick={this.handleAgrre} className={this.state.chk_addr ? "btnTy2 on" : "btnTy2"}>확인</a>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}
}

export default withRouter(PermissionAddr)