import React from "react";
import ReactDOM from "react-dom";
import Modal from './CustomModal'
import { Modal as antd_Modal } from 'antd'
import { locAddressMainVar } from './ApolloClient'
import $ from 'jquery'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const gqlErrorHandler = (err, tip = null) => {
	console.log(err);
	antd_Modal.confirm({
		title: "오류가 발생했습니다. " + (tip !== null ? `(${tip})` : ''),
		okText: "새로고침",
		cancelText: "취소",
		onOk() {
			window.location.reload();
		},
		onCancel() { },
	});
}

const successHandler = (contentText) => {
	Modal.success({
		content: contentText
	});
}

const warningHandler = (titleText, contentText) => {
	Modal.warning({
		title: titleText,
		content: contentText
	});
}

const isLogin = () => {
	const time = Math.round(new Date().getTime() / 1000)
	if (time >= localStorage.getItem("token_expires")) return false;

	if (localStorage.access_token && localStorage.member_email) {
		return true;
	} else {
		return false;
	}
}

const getLocation = (callback, justReturnDefault) => {
	const defaultLL = { coords: { latitude: 37.4975114, longitude: 127.0267694 } } // 에러, 위치접근권한미부여 등 일때
	if (justReturnDefault) return defaultLL
	const opt = {
		enableHighAccuracy: false,
		timeout: 5000,
		maxiumAge: 60000,
	}
	try {
		navigator.geolocation.getCurrentPosition(
			(pos) => callback(pos, true),
			(err) => {
				console.error(err);
				callback(defaultLL, false)
			},
			opt
		);
	} catch (err) {
		console.error(err);
		callback(defaultLL, false)
	}
}
let kakaoMap
const createKakaoMap = (opt, callback) => {
	const appKey = '7caa9e9a4de93f51d6ed485e9874e22b'
	const script = document.createElement("script")
	script.async = true
	script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${appKey}&libraries=services&autoload=false`
	script.id = 'kakao-map-script'
	document.head.appendChild(script)

	script.onload = () => {
		window.kakao.maps.load(() => {
			let container = document.getElementById(opt.id)
			let options = {
				center: new window.kakao.maps.LatLng(opt.lat, opt.lng),
				level: opt.level,
			};

			kakaoMap = new window.kakao.maps.Map(container, options)
			callback && callback(kakaoMap)
		});
	}
}

const createDaumPostcode = (opt, ocCallback, osCallback) => {
	const script = document.createElement("script")
	script.async = true
	script.src = `https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js`
	script.id = 'daum-postcode-script'
	document.head.appendChild(script)

	script.onload = () => {
		const element_wrap = document.getElementById(opt.id)
		new window.daum.Postcode({
			oncomplete: function (data) {
				ocCallback(data)
			},
			// 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
			onresize: function (size) {
				element_wrap.style.height = size.height + 'px';
			},
			width: '100%',
			height: '100%',
			hideMapBtn: true,
			onsearch: function (data) {
				osCallback(data)
			},
			theme: { bgColor: '#FFFFFF' }
		}).embed(element_wrap);
	}
}

const saveLoginMember = (id, nick, email, mobile) => {
	// 로그인관련 값 localstorage 저장
	localStorage.member_id = id;
	localStorage.member_nick = nick;
	localStorage.member_email = email;
	localStorage.member_mobile = mobile;

	if (id) {
		/* global AppScheme */
		typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://member?id=${id}`);
	}

	let pathname = '/';
	if (localStorage.prevpath) {
		pathname = localStorage.prevpath;
		localStorage.prevpath = '';
	}
	window.location.replace(pathname);
}
const chkImageUrl = (url) => {
	if (!url) {
		return '';
	}
	let url_ = String(url)
	if (/:\/\//.test(url_) === false) {
		url_ = process.env.REACT_APP_IMAGES_DOMAIN + url_ // 도메인 없는 데이터.
	}
	return url_
}
export const setLocAddressMain = (addr) => {
	/* 사용될 필드 참고용으로 기본구조 정의하고 새 값과 머지 */
	let locAddr = {
		oldAddr1: null,
		oldAddr2: null,
		addr1: null,
		addr2: null,
		sido: null,
		gugun: null,
		dong: null,
		lng: null,
		lat: null,
		hCode: null,
		bCode: null,
	}
	locAddr = addr ? { ...locAddr, ...addr } : null;

	/* 로컬스토리지에 저장 & 로컬스테이트 갱신 */
	localStorage.setItem("locAddressMain", JSON.stringify(locAddr))
	locAddressMainVar(locAddr)
}
export { gqlErrorHandler, successHandler, warningHandler, getLocation, createKakaoMap, kakaoMap, createDaumPostcode, saveLoginMember, chkImageUrl, isLogin }

export const headerShadow = function () {
	$('#reWrap').scroll(function () {
		hdScrollWrap();
	});
	$(window).scroll(function () {
		hdScrollWin();
	});
	function hdScrollWrap() {
		if ($('#reWrap').scrollTop() === 0) {
			$('#header').removeClass('scroll');
		} else {
			$('#header').addClass('scroll');
		}
	}
	function hdScrollWin() {
		if ($(window).scrollTop() === 0) {
			$('#header').removeClass('scroll');
		} else {
			$('#header').addClass('scroll');
		}
	}
	hdScrollWrap();
	hdScrollWin();
}
export const bodyScrollLock = {
	posY: 0,
	lock() {
		const body = document.querySelector('body');
		this.posY = window.pageYOffset;
		body.style.overflow = 'hidden';
		body.style.position = 'fixed';
		body.style.top = `-${this.posY}px`;
		body.style.width = '100%';
		body.classList.add('nowBodyScrollLock')
	},
	unlock() {
		const body = document.querySelector('body');
		body.style.removeProperty('overflow');
		body.style.removeProperty('position');
		body.style.removeProperty('top');
		body.style.removeProperty('width');
		window.scrollTo(0, this.posY);
		body.classList.remove('nowBodyScrollLock')
	}
}

export const handleToOrder = (id, e, h) => {
	e.preventDefault()

	const windowOpenShop = () => {
		const tokenStr = localStorage.getItem('access_token') ? `?token=${localStorage.getItem('access_token')}` : ''
		window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/shop/${id}${tokenStr}`)
	}

	let las = JSON.parse(localStorage.getItem('loginAlertShown'))

	if (!las || dayjs().format('YYYY-MM-DD') > las.lastDt) { // 없거나 오늘이 최종보다 이후의 날이라면 값초기화.
		las = { cnt: 0, lastDt: dayjs(0).format('YYYY-MM-DD HH:mm:ss') }
		localStorage.setItem('loginAlertShown', JSON.stringify(las))
	}

	if (!isLogin() && las.cnt < 3 && dayjs().isAfter(dayjs(las.lastDt).add(1, 'hour'))) { // 미로그인 and 1일 3회 이상 노출 X and 1회 노출 후 최소 1시간 이후 노출
		const container = document.createElement("div")
		document.getElementById('reWrap').appendChild(container)

		function LoginAlert() {
			const remove = (e) => {
				e && e.preventDefault()
				ReactDOM.unmountComponentAtNode(container) //이거진행해야 react컴포넌트트리에서도삭제됨...
				container.remove()
			}
			const close = (e) => {
				remove(e)
				windowOpenShop()
			}
			const goLogin = (e) => {
				remove(e)
				h && h.push('/login')
			}
			const goJoin = (e) => {
				remove(e)
				h && h.push('/mobile-confirm/join')
			}
			return (
				<div className="loginPopWrap">
					<div className="loginPopCon">
						<a href="#this" className="logPopClose" onClick={close}>닫기</a>
						<em>로그인이 필요한 서비스입니다.</em>
						<strong>아직 회원이 아니시라면 회원가입 후 <span>‘어디go’</span> 만의 혜택을 받아보세요!</strong>
						<a href="#this" className="logPopLink" onClick={goLogin}>로그인</a>
						<a href="#this" className="logPopJoin" onClick={goJoin}>회원가입 하러가기</a>
					</div>
				</div>
			)
		}
		ReactDOM.render(<LoginAlert />, container)

		//최종표시 기록
		las = { cnt: las.cnt + 1, lastDt: dayjs().format('YYYY-MM-DD HH:mm:ss') }
		localStorage.setItem('loginAlertShown', JSON.stringify(las))
	} else {
		windowOpenShop()
	}
}