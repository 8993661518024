import React from "react";
import { withRouter, NavLink, Route, Link } from "react-router-dom";
import InputDelButton from './InputDelButton';
import FindEmail from './FindEmail';
import FindPassword from "./FindPassword";
import axios from "axios";
import Modal from './CustomModal';
import AgreeTerms from "./AgreeTerms";
import { withApolloClient } from './ApolloClient';
import { gql, loader } from 'graphql.macro';
import { gqlErrorHandler, saveLoginMember } from './Library';

const memberCheckGql = gql`
	query memberCheck($gid: Int) {
		gongMemberCheck(gongId: $gid) 
	}
`
const SET_SHOP_PUSH_MUTATION_GQL = loader('../gql/set_push_shop_setting.gql');
const PUSH_SETTING_GQL = loader('../gql/push_setting.gql');

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: { email: '', password: '' },
			member: { id: '', nick: '', email: '', mobile: '' },
			toAgree: false
		}

		let code = new URLSearchParams(window.location.search).get("code");
		if (code && this.state.toAgree === false) {
			const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_SERVICE_URI + window.location.pathname);
			const authStr = 'Basic ' + btoa(localStorage.client_id + ':' + localStorage.client_secret);

			// 인증을 거치고 온경우
			// 토큰생성
			axios.post(`${process.env.REACT_APP_SERVER_REST}/v2/oauth/member/token`,
				`grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URI}`,
				{
					headers: { 'Authorization': authStr }
				})
				.then(res => {
					// 토큰생성 성공
					if (res.data.access_token && res.data.refresh_token) {
						localStorage.access_token = res.data.access_token;
						localStorage.refresh_token = res.data.refresh_token;
						localStorage.token_expires = Math.round(new Date().getTime() / 1000) + parseInt(res.data.expires_in);
						// MemberInfo
						axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
							{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
							.then(res => {
								if (res.data.result) {
									// 해당 앱의 약관동의를 거친지 확인
									this.props.client.query({
										query: memberCheckGql,
										variables: { gid: process.env.REACT_APP_SERVICE_GID },
										fetchPolicy: 'network-only',
									}).then((data) => {
										if (data.data.gongMemberCheck) {
											// 로그인처리후 replace
											saveLoginMember(res.data.id, res.data.nick, res.data.email, res.data.phone);

											// 회원이지만 공공앱 처음 로그인 시
										} else {
											// 약관 동의후 로컬스토리지 저장을 위해 회원정보 임시저장
											let member = {
												id: res.data.id,
												nick: res.data.nick,
												email: res.data.email,
												mobile: res.data.phone
											}
											this.setState({ toAgree: true, member: member });
										}

									}).catch(() => { Modal.error({ content: '앱정보 처리중 오류가 발생하였습니다.' }); });
								}

							}).catch(res => { Modal.error({ content: '회원정보를 가져오는중 오류가 발생하였습니다.' }); });
					} else {
						// 토큰생성 실패 메세지 노출
						Modal.warning({ content: res.data.msg });
					}

				}).catch(res => { Modal.error({ content: '토큰 생성중 오류가 발생하였습니다.' }); });
		}

		this.handleInput = this.handleInput.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleAgree = this.handleAgree.bind(this);
		this.handleAgreeBack = this.handleAgreeBack.bind(this);		
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}	
	handleAgree(isAddAgree) {
		// 앱회원 등록
		// 기본 PUSH
		this.props.client.query({
			query: PUSH_SETTING_GQL,
			variables: { gongId: process.env.REACT_APP_SERVICE_GID },
			fetchPolicy: 'network-only',
		}).then(() => {
			// 마케팅수신동의
			this.props.client
				.mutate({
					mutation: SET_SHOP_PUSH_MUTATION_GQL,
					variables: { shopId: 0, agree: isAddAgree }
				}).then((data) => {
					if (!data.data.memberAgree.result) {
						gqlErrorHandler(data.data.memberAgree.msg);
					}

					// 로그인처리후 replace
					saveLoginMember(this.state.member.id, this.state.member.nick, this.state.member.email, this.state.member.mobile);

				})
				.catch((err) => { gqlErrorHandler(err) });

		}).catch((err) => { gqlErrorHandler(err) });


	}

	handleInput(mode, value) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === mode ? values[key] = value : null);
		this.setState({ values: values });
	}

	handleLogin(e) {
		e.preventDefault();
		if (this.state.values.email && this.state.values.password) {
			const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_SERVICE_URI + window.location.pathname);
			axios.post(`${process.env.REACT_APP_SERVER_REST}/v2/oauth/member/create_client`,
				`email=${this.state.values.email}&password=${this.state.values.password}&redirect_uri=${REDIRECT_URI}`)
				.then(res => {
					if (res.data.result) {
						localStorage.client_id = res.data.client_id;
						localStorage.client_secret = res.data.client_secret;

						window.location.replace(`${process.env.REACT_APP_SERVER_REST}/v2/oauth/member/authorize?client_id=${res.data.client_id}&redirect_uri=${REDIRECT_URI}&response_type=code`)
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '로그인처리중 오류가 발생하였습니다.' }) });
		}
	}
	handleAgreeBack(e) {
		e.preventDefault();
		this.setState({toAgree: false});
	}

	render() {
		if (this.state.toAgree) {
			return (<AgreeTerms onAgree={this.handleAgree} onBack={this.handleAgreeBack}  />)
		} else {
			let buttonClass = 'btnTy2';
			if (this.state.values.email && this.state.values.password) {
				buttonClass += ' on';
			}
			return (
				<>
					<div id="reWrap">
						<div id="header" className="subHeader">
							<div className="header">
								<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide btnHback">뒤로가기</a>
								<h1>로그인</h1>
							</div>
						</div>
						<div id="container">
							<div id="contents" className="subCnts4">
								<div className="cmLoginBox">
									<div className="tabUi">
										<ul>
											<li><button className="on" data-conts="email">이메일 로그인</button></li>
											<li><Link to="/mobile-confirm/login" replace><button data-conts="phone">휴대폰 로그인</button></Link></li>
										</ul>
									</div>
									<div className="loginSectoin">
										<div className="tabSectoin email on" id="email">
											<ul>
												<li>
													<div className="inputSt1">
														<InputDelButton type="email" name="email" className="clearInput" placeholder="이메일을 입력하세요" onInput={this.handleInput} />
														<div className="inputFocus"></div>
													</div>
												</li>
												<li>
													<div className="inputSt1">
														<InputDelButton type="password" name="password" className="clearInput" placeholder="비밀번호를 입력하세요" onInput={this.handleInput} />
														<div className="inputFocus"></div>
													</div>
												</li>
											</ul>
											<div className="btnCenter">
												<a href="#this" className={buttonClass} onClick={this.handleLogin}>로그인</a>
												<NavLink to="/mobile-confirm/join" className="btnTy3 mt12">회원가입</NavLink>
											</div>
											<div className="idpwFind">
												<span><Link to="/login/find-email" className="layerIdFind_open">아이디 찾기</Link></span>
												<span><Link to="/login/find-password" className="layerPwFind_open">비밀번호 찾기</Link></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Route path='/login/find-email'>
						<FindEmail />
					</Route>

					<Route path='/login/find-password'>
						<FindPassword />
					</Route>
				</>
			);
		}
	}
}

export default withApolloClient(withRouter(Login))