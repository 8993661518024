import React from "react";
import Modal from './CustomModal';

const startYear = 1920;
const endYear = parseInt(new Date().getFullYear());
const yearOptions = [];
const monthOptions = [];
const dayOptions = [];
for (var i = endYear; i >= startYear; i--) {
	yearOptions.push(i);
}
for (i = 1; i <= 12; i++) {
	monthOptions.push(i.toString().padStart(2, '0'));
}
for (i = 1; i <= 31; i++) {
	dayOptions.push(i.toString().padStart(2, '0'));
}

export default class BirthForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {values: {year: '', month: '', day: ''}};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(e) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === e.target.name ? values[key] = e.target.value : '');
		this.setState({values: values});
	}

	handleSubmit(e) {
		e.preventDefault();
		if (this.state.values.year && this.state.values.month && this.state.values.day) {
			this.props.onSubmit(this.state.values.year + this.state.values.month + this.state.values.day);
		} else {
			Modal.warning({content: '날짜를 선택해주세요.'});
		}
	}
	
	render() {
		const {year, month, day} = this.state.values;
		const onClassSubmit = year && month && day ? 'on' : '';
		return(
			<>
				<div id="layerBirthChange_wrapper" className="popup_wrapper popup_wrapper_visible" style={{ opacity: 1, visibility: 'visible', position: 'fixed', overflow: 'auto', zIndex: 101, transition: 'all 0.3s ease 0s', width: '100%', height: '100%', top: 0, left: 0, textAlign: 'center', pointerEvents: 'none' }}>		
					<div id="layerBirthChange"  data-popup-initialized="true" className="popup_content" aria-hidden="false" role="dialog" aria-labelledby="open_55681474" tabIndex="-1" style={{ top: '50vh', transform: 'translateY(-50%)', opacity: 1, visibility: 'visible', pointerEvents: 'auto', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle' }}>
						<div className="layerBox">
							<div className="layerHd">
								<h3>생년월일 변경</h3>
							</div>
							<div className="layerCnt">
								<div className="layerCom">
									<h4>생년월일</h4>
									<div className="box">
										<div className="birth">
											<div className="selectBox01">
												<select name="year" value={this.state.values.year} onChange={this.handleChange}>
													<option value="">년</option>
													{yearOptions.map((value) => <option key={value} value={value}>{value}</option>)}
												</select>
											</div>
											<div className="selectBox01">
												<select name="month" value={this.state.values.month} onChange={this.handleChange}>
													<option value="">월</option>
													{monthOptions.map((value) => <option key={value} value={value}>{value}</option>)}
												</select>
											</div>
											<div className="selectBox01">
												<select name="day" value={this.state.values.day} onChange={this.handleChange}>
												<option value="" >일</option>
												{dayOptions.map((value) => <option key={value} value={value}>{value}</option>)}
												</select>
											</div>
										</div>
									</div>
									<div className="btnCenter">
										<a href="#this" className={`btnTy2 ${onClassSubmit}`} onClick={this.handleSubmit}>저장</a>
									</div>
								</div>
							</div>
							<a href="#this" className="txtHide btnLayerClose1 layerBirthChange_close" onClick={this.props.onClear}>닫기</a>
						</div>
					</div>
				</div>
				<div id="layerBirthChange_background" className="popup_background" onClick={this.props.onClear} style={{ opacity: 0.6, visibility: 'visible', backgroundColor: 'black', position: 'fixed', inset: 0, zIndex: 100, transition: 'all 0.3s ease 0s' }}></div>
			</>			
		);
	}
}