import React, { useRef } from "react";
import { useQuery } from '@apollo/client';
import useShopListParam from '../hook/useShopListParam'
import { loader } from 'graphql.macro'
import { gqlErrorHandler } from './Library'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ShopUnit from './ShopUnit'
import { useLocation } from 'react-router-dom'
const shopListGql = loader('../gql/shop_list.gql')

export default function ShopListLoader(props) {
	const location = useLocation();
	if (typeof props.match !== 'undefined' && location.pathname !== props.match.path) {
		return <></>
	} else {
		return <ShopList {...props} />;
	}
}
function ShopList(props) {
	//const location = useLocation();
	const doFetchMore = useRef(true)
	const values = useShopListParam(props)
	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

	const handleFetchMore = async (fm, data) => {
		if (!data || data.gongShops.pageInfo.hasNextPage === false) return
		if (doFetchMore.current === false) return

		doFetchMore.current = false

		await fm({
			variables: {
				cursor: data.gongShops.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				if (previousResult.gongShops.pageInfo.endCursor === fetchMoreResult.gongShops.pageInfo.endCursor) {
					// data가 갱신되기 전에 또다시 호출되어 그 전 data.gongShops.pageInfo.endCursor값으로 다시 페치되는 경우가 있음. 중복된 데이터이므로 이때는 그 전 결과를 리턴.
					console.log('prev result')
					return previousResult
				}
				const newEdges = fetchMoreResult.gongShops.edges;
				const pageInfo = fetchMoreResult.gongShops.pageInfo;

				return newEdges.length
					? {
						gongShops: {
							__typename: previousResult.gongShops.__typename,
							edges: [...previousResult.gongShops.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		})

		doFetchMore.current = true
	}
	const { loading, error, data, fetchMore } = useQuery(shopListGql, { variables: values });
	useBottomScrollListener(() => handleFetchMore(fetchMore, data))
	if (loading)
		return (
			<div style={{ paddingLeft: '48%', paddingTop: '50%', marginTop: 5 }}>
				<Spin tip="" indicator={antIcon} />
			</div>
		)
	if (error) { gqlErrorHandler(error); return null }

	return (
		<div className="storeListView">
			{data.gongShops.edges.length > 0
				? <>
					<ul>
						{data.gongShops.edges.map((item) => (
							<ShopUnit {...item.node} key={item.node.shop.pk} />
						))}
					</ul>
					{data.gongShops.pageInfo.hasNextPage === true &&
						<div style={{ paddingLeft: '48%', marginTop: 5 }}>
							<Spin tip="" indicator={antIcon} />
						</div>
					}
				</>
				: <ul>
					<li className="slvNodata">
						<div className="box">현재 고객님이 설정한 위치 기준으로<br />노출되는 매장이 없습니다.</div>
					</li>
				</ul>
			}
		</div>
	)
}