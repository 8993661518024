import React from "react";
import Footer from './Footer';
import PushMark from './PushMark';
import { NavLink, withRouter } from "react-router-dom";
import { isLogin } from './Library';
import { withApolloClient } from './ApolloClient';

class Mypage extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (isLogin()) {
			return (<MemberMypage  {...this.props} />)
		} else {
			return (<NonMemberMypage  {...this.props} />)
		}
	}
}
class NonMemberMypage extends React.Component {
	goLogin = function goLogin(e) {
		localStorage.prevpath = '/mypage';
		window.location.href = '/login';
	};	
	render() {
		let version = localStorage.app_version ? localStorage.app_version : '1.0.0';
		return (
			<div id="reWrap">
				<div id="header" className="subHeader3">
					<div className="header">
						<h1 className="txtH1">마이페이지</h1>
						<div className="btnHr">
							<PushMark />
						</div>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts5">
						<div className="mypageSetion">
							<div className="mpLoginBox">
								<a href="#this" onClick={this.goLogin} className="loginGo">로그인이 필요합니다</a>
							</div>
							<hr className="hrBk" />
							<div className="mpMenu">
								<ul>
									<li className="mp7"><NavLink to="/event">이벤트/혜택</NavLink></li>
									<li className="mp5"><NavLink to="/mypage/notice">공지</NavLink></li>
									<li className="mp6"><NavLink to="/mypage/faq">문의</NavLink></li>
								</ul>
							</div>
							<hr className="hrBk" />
							<div className="mpListMenu">
								<ul>
									<li><NavLink to="/mypage/terms-list">이용약관</NavLink></li>
									<li><span>현재버전<em>v {version}</em></span></li>
								</ul>
							</div>
							<div className="csNumDiv">
								<p><a href="tel:1522-4040">어디고 고객센터<strong>1522-4040</strong></a></p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

class MemberMypage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			memberInfo: {
				name: localStorage.member_nick ? localStorage.member_nick : '',
				email: localStorage.member_email ? localStorage.member_email : '',
				mobile: localStorage.member_mobile ? localStorage.member_mobile : ''
			}
		};
	}

	render() {
		const memberInfo = this.state.memberInfo;
		let version = localStorage.app_version ? localStorage.app_version : '1.0.0';
		return (
			<div id="reWrap">
				<div id="header" className="subHeader3">
					<div className="header">
						<h1 className="txtH1">마이페이지</h1>
						<div className="btnHr">
							<PushMark />
						</div>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts5">
						<div className="mypageSetion">
							<div className="mpLoginBox">
								<span className="name">{memberInfo.name !== '' ? memberInfo.name : memberInfo.mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")}</span>
								<ul>
									<li>{memberInfo.email}</li>
									{memberInfo.name !== '' && <li>{memberInfo.mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")}</li>}
								</ul>
								<NavLink to="/mypage/set-info" className="btnSetup"><img src="/img/reImg/common/btn_setup.png" alt="설정" /></NavLink>
							</div>
							<hr className="hrBk" />
							<div className="mpMenu">
								<ul>
									<li className="mp1"><NavLink to="/mypage/coupon">쿠폰</NavLink></li>
									<li className="mp2"><NavLink to="/order">주문</NavLink></li>
									<li className="mp3"><NavLink to="/mypage/review">리뷰</NavLink></li>
									<li className="mp4"><NavLink to="/mypage/bookmark">찜</NavLink></li>
									<li className="mp5"><NavLink to="/mypage/notice">공지</NavLink></li>
									<li className="mp6"><NavLink to="/mypage/faq">문의</NavLink></li>
								</ul>
							</div>
							<hr className="hrBk" />
							<div className="mpListMenu">
								<ul>
									<li><NavLink to="/mypage/set-address">내 주소 설정</NavLink></li>
									<li><NavLink to="/event">이벤트/혜택</NavLink></li>
									<li><NavLink to="/mypage/set-message">알림/푸쉬</NavLink></li>
									<li><NavLink to="/mypage/terms-list">이용약관</NavLink></li>
									<li><span>현재버전<em>v {version}</em></span></li>
								</ul>
							</div>
							<div className="csNumDiv">
								<p><a href="tel:1522-4040">어디고 고객센터<strong>1522-4040</strong></a></p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withApolloClient(withRouter(Mypage))